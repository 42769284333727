import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'

import { reducer as ui } from './ui'

/**
 * Creating a redux store
 * @returns The store
 */
export const createStore = () => {
  const that = configureStore({
    reducer: {
      ui,
    },
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        thunk: true,
        serializableCheck: true,
        immutableCheck: true
      }),
    ],
    devTools: process.env.NODE_ENV !== 'production'
  })
  setupListeners(that.dispatch)

  return that
}

export default createStore
