/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import React from 'react'

export const Here2 = () => (
  <>
    <div id="co_document" className="co_document co_codesStateAdminCodes">
      <div className="co_documentHead">
        <div className="co_cites">14 CCR § 18993.2</div>
        <div className="co_title">
          <div id="co_anchor_I44924C967FE64C8DB73ED8A2AA3EA2BD" className="co_headtext">
            <strong>
              § 18993.2. Recordkeeping
              Requirements For Recovered Organic Waste Procurement Target.
            </strong>

          </div>
        </div>
      </div>
      <div className="co_contentBlock co_section" id="co_anchor_ICF9B0C903A0947049BA9197C567307B4">
        <div className="co_contentBlock co_body">
          <div className="co_contentBlock co_subsection">
            <div className="co_paragraph" id="co_anchor_I7D1F138B45F24E3A8D024E7BB9BC8CB5">
              <div className="co_paragraphText">
                (a) A jurisdiction, as defined in 18993.1(a), shall include all documents
                supporting its compliance with this article in the implementation record required by Section 18995.2 of this
                chapter including, but not limited to, the following:

              </div>
            </div>
            <div className="co_contentBlock co_subsection">
              <div className="co_paragraph" id="co_anchor_I422CB7D9D489467BB4C1AE37F601C2D0">
                <div className="co_paragraphText co_indentLeft2">
                  (1) A description of how the jurisdiction will comply with the
                  requirements of this article.

                </div>
              </div>
            </div>
            <div className="co_contentBlock co_subsection">
              <div className="co_paragraph" id="co_anchor_I9126E486F4D64E2DBBBFA4E36233D15D">
                <div className="co_paragraphText co_indentLeft2">
                  (2) The name, physical location, and contact information of
                  each entity, operation, or facility from whom the recovered organic waste products were procured, and a
                  general description of how the product was used, and if applicable, where the product was applied.

                </div>
              </div>
            </div>
            <div className="co_contentBlock co_subsection">
              <div className="co_paragraph" id="co_anchor_I2467098EA0F943BEB100E2147C577198">
                <div className="co_paragraphText co_indentLeft2">
                  (3) All invoices or similar records evidencing all procurement.
                </div>
              </div>
            </div>
            <div className="co_contentBlock co_subsection">
              <div className="co_paragraph" id="co_anchor_I730DEAAA58FF4BAA9B61A615B262C9EE">
                <div className="co_paragraphText co_indentLeft2">
                  (4) If a jurisdiction will include procurement of recovered
                  organic waste products made by a direct service provider to comply with the procurement requirements of
                  Section 18993.1(a), the jurisdiction shall include all records of procurement of recovered organic waste
                  products made by the direct service provider on behalf of the jurisdiction including invoices or similar
                  records evidencing procurement.

                </div>
              </div>
            </div>
            <div className="co_contentBlock co_subsection">
              <div className="co_paragraph" id="co_anchor_ID4C347F6A1794D699051C51C9B4EA517">
                <div className="co_paragraphText co_indentLeft2">
                  (5) If a jurisdiction will include renewable gas procured from
                  a POTW for any of the uses identified in 18993.1(f)(2) to comply with the procurement mandate of Section
                  18993.1(a), a written certification by an authorized representative of the POTW, under penalty of perjury
                  in a form and manner determined by the jurisdiction, attesting to the following for the applicable
                  procurement compliance year:

                </div>
              </div>
              <div className="co_contentBlock co_subsection">
                <div className="co_paragraph" id="co_anchor_I10CB547F61B64D7ABAC51F5054A91B62">
                  <div className="co_paragraphText co_indentLeft2">
                    (A) That the POTW was in compliance with the exclusion in
                    Section 17896.6(a)(1);

                  </div>
                </div>
              </div>
              <div className="co_contentBlock co_subsection">
                <div className="co_paragraph" id="co_anchor_IE36F706E14C44B67BFC3DF7FC3C423B7">
                  <div className="co_paragraphText co_indentLeft2">
                    (B) The total tons of organic waste received from the types
                    of solid waste facilities listed in Section 18993.1(h)(1); and

                  </div>
                </div>
              </div>
              <div className="co_contentBlock co_subsection">
                <div className="co_paragraph" id="co_anchor_I62C7317EE65E461BB337F1580033EF1D">
                  <div className="co_paragraphText co_indentLeft2">
                    (C) The percentage of biosolids that the POTW produced and
                    transported to activities that constitute landfill disposal.

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="co_contentBlock co_subsection">
            <div className="co_paragraph" id="co_anchor_I213FDEBD56AF4C2DA1478B2504A73B7A">
              <div className="co_paragraphText co_indentLeft2">
                (6) If a jurisdiction will include electricity procured from a
                biomass conversion facility to comply with the procurement mandate of Section 18993.1(a), a written
                certification by an authorized representative of the biomass conversion facility certifying that biomass
                feedstock was received from a permitted solid waste facility identified in 18993.1(i) shall be provided to
                the jurisdiction. The certification shall be furnished under penalty of perjury in a form and manner
                determined by the jurisdiction.

              </div>
            </div>
          </div>
          <div className="co_contentBlock co_subsection">
            <div className="co_paragraph" id="co_anchor_IFF74641C12C6459DB7004016263C942D">
              <div className="co_paragraphText co_indentLeft2">
                (7) If the jurisdiction is implementing the procurement
                requirements of Section 18993.1 through an adjusted recovered organic waste product procurement target
                pursuant to Section 18993.1(j), the jurisdiction shall include records evidencing the total amount of
                transportation fuel, electricity, and gas for heating applications procured during the calendar year prior
                to the applicable reporting period.

              </div>
            </div>
          </div>
          <div className="co_contentBlock co_subsection">
            <div className="co_paragraph" id="co_anchor_I250E937857E74E788EEDD76E3370C2B1">
              <div className="co_paragraphText co_indentLeft2">
                (8) For jurisdictions complying with the requirements of Section
                18993.1, through the procurement of mulch, a copy of the ordinance or similarly enforceable mechanism the
                jurisdiction has adopted requiring that mulch procured by the jurisdiction or a direct service provider
                meets the land application standards specified in Section 18993.1.

              </div>
            </div>
          </div>
        </div>
        <div className="co_subsection">
          <div className="co_paragraph" id="co_anchor_IC67115C07FFF4759845F0E21DFEE3D97">
            <div className="co_paragraphText co_indentFirstLine1">
              Note: Authority cited: Sections 40502, 43020, 43021 and
              42652.5, Public Resources Code. Reference: Section 42652.5, Public Resources Code; and Section 39730.6, Health and Safety Code.

            </div>
          </div>
        </div>
        <div id="co_anchor_IDD448B11B56344A3AB50C60ABDC3DABF" className="co_headtext co_hAlign2">HISTORY</div>
        <div className="co_paragraph" id="co_anchor_I0E97D712F3A045818489CAFCA110EEAF">
          <div className="co_paragraphText">
            1. New section filed 11-3-2020; operative 1-1-2022 pursuant to Public Resources Code
            section 42652.2(a)(6) (Register 2020, No. 45).

          </div>
        </div>
        <div className="co_contentBlock co_includeCurrencyBlock" id="co_anchor_IEBC8DC7246D94ADDB2BBEC54B8F376BB">
          This database
          is current through 9/24/21 Register 2021, No. 39

        </div>
      </div>
      <div className="co_contentBlock co_cmdExpandedcite">14 CCR § 18993.2, 14 CA ADC § 18993.2</div>
      <table id="co_endOfDocument">
        <tbody>
          <tr>
            <td>End of Document</td>
          </tr>
        </tbody>
      </table>
    </div>
  </>
)
