import React from 'react'

export const InputMapping = ({ item, errors, register }: {item: any, errors: any, register: any}) => (
  <label htmlFor={item.name} style={{ position: 'relative' }}>
    {item.title}
    {item.type !== 'textarea' ? (
        <input
          type={item.type}
          {...register(item.name)}
          placeholder={item.placeholder}
          style={{ borderBottomColor: errors.name ? 'red' : '#366d4f' }}
        />
      ) : (
        <textarea
          {...register(item.name)}
          placeholder={item.placeholder}
          style={{ borderBottomColor: errors.message ? 'red' : '#366d4f' }}
        />
    )}
    {errors[item.name] && (
      <span style={{
        fontSize: '14px', color: 'red', position: 'absolute', left: '-10px', bottom: item.type !== 'textarea' ? '-22px' : '-2px', backgroundColor: 'white', padding: '0 10px'
      }}
      >
        {errors[item.name]?.message}
      </span>
    )}
  </label>
)

export default InputMapping
