import {
  useCallback, useEffect, useRef, useState
} from 'react'
import { useDispatch } from 'react-redux'

import { useRouter } from 'next/router'

import { ComponentsAsType } from '../../configs/constants'
import {
  hideDialog, hideModal, showDialog, showModal
} from '../../store/ui'
import { Size } from '../../types/pageData'

/**
 * Set the body class for the specific page
 * @param newClass className or classes
 * @returns nothing
 */
export const useBodyClass = (newClass: string, conditionalRender = true) => {
  useEffect(() => {
    if (newClass && conditionalRender) {
      // eslint-disable-next-line react/destructuring-assignment
      if (typeof window !== "undefined") {
        const newClasses = newClass.split(' ')
        const el = document.body
        newClasses.forEach((item) => el.classList.add(item))
        return () => {
          setTimeout(() => {
            newClasses.forEach((item) => el.classList.remove(item))
          }, 200)
        }
      }
    }
    return () => {}
  }, [newClass, conditionalRender])
  return null
}

/**
 * @example
 * ```javascript
 * dispatch(showModal({ component: Components.News, title: 'Hello' }))
 * ```
 * @returns Modal
 */
export const useModal = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  return {
    open: (title: string, component: ComponentsAsType, params: unknown = {}) => {
      dispatch(showModal({ component, title, params }))
    },
    close: () => {
      if (typeof window !== "undefined") {
        router.replace(window.location.href.split('#')[0])
      }
      dispatch(hideModal())
    }
  }
}

/**
 * @example
 * ```javascript
 * dispatch(useDialog({ component: Components.News, title: 'Hello' }))
 * ```
 * @returns Modal
 */
export const useDialog = () => {
  const dispatch = useDispatch()
  return {
    open: (title: string, component: string) => {
      dispatch(showDialog({ component, title }))
    },
    close: () => {
      dispatch(hideDialog())
    }
  }
}

/**
 * Return a ref for specific element
 * @example
 * ```javascript
 * const scrollCallback = useCallback((e) => {
 *  const maxScroll = e.target.scrollHeight - e.target.offsetHeight;
 *  const scrollTop = e.target.scrollTop;
 *  const difference = maxScroll - scrollTop;
 *  if (difference <= 0 && !finished) {
 *    fetchData();
 *  }
 * }, [finished, fetchData])
 *
 * const ref = useComponentScrollHook(scrollCallback);
 * ```
 * @param callBack Function to be coleed onScroll event
 * @returns ref
 */
export const useComponentScroll = (callBack: unknown) => {
  const ref = useRef(null) as any

  useEffect(() => {
    if (ref.current && callBack) {
      ref.current.addEventListener('scroll', callBack)
    }

    return () => {
      if (ref.current && callBack) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ref.current.removeEventListener('scroll', callBack)
      }
    }
  }, [ref, callBack])

  return ref
}

/**
 * useWindowSize Returns size of the window
 * @example
 * ```javascript
 * const size = useWindowSize();
 * return (
 *  <div>
 *    {size.width}px / {size.height}px
 *  </div>
 * );
 * ```
 *
 * @returns object {
 *  width: undefined | number,
 *  height: undefined | number
 * }
 */
export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined
  })
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

export const useIsMobileSize = (size = 1000) => {
  const sizes = useWindowSize()
  return sizes.width !== undefined && sizes.width <= size
}

/**
 * useScroll React custom hook
 * Usage:
 *    const { scrollX, scrollY, scrollDirection } = useScroll();
 * Original Source: https://gist.github.com/joshuacerbito/ea318a6a7ca4336e9fadb9ae5bbb87f4
 */
export const useScroll = () => {
  const [state, setState] = useState({
    lastScrollTop: 0,
    bodyOffset: undefined,
    scrollY: 0,
    scrollX: 0,
    scrollDirection: '' // down, up
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleScrollEvent = useCallback((_e) => {
    setState((prevState: any) => {
      const prevLastScrollTop = prevState.lastScrollTop
      const bodyOffset = document.body.getBoundingClientRect()

      return {
        bodyOffset,
        scrollY: -bodyOffset.top,
        scrollX: bodyOffset.left,
        scrollDirection: prevLastScrollTop > -bodyOffset.top ? 'down' : 'up',
        lastScrollTop: -bodyOffset.top
      }
    })
  }, [])

  useEffect(() => {
    const scrollListener = (e: any) => {
      handleScrollEvent(e)
    }
    window.addEventListener('scroll', scrollListener)

    return () => {
      window.removeEventListener('scroll', scrollListener)
    }
  }, [handleScrollEvent])

  return {
    scrollY: state.scrollY,
    scrollX: state.scrollX,
    scrollDirection: state.scrollDirection
  }
}

export default useBodyClass
