import React from 'react'

export type ErrorProps = {
  description: string
}

export const Error = ({
  description
}: ErrorProps) => (
  <div>
    <p>{description}</p>
  </div>
)

export default Error
