import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  IconButton, IIconProps, Modal, Stack, StackItem, Text
} from '@fluentui/react'
import classNames from 'classnames'

import Tracking from '../../../lib/gtag'
import { useBodyClass } from '../../../lib/hooks/app-hooks'
import { useSelector } from '../../../lib/hooks/store-hooks'
import { hideModal } from '../../../store/ui'
import styles from '../../../styles/modal.module.scss'
import { MapComponents } from '../../map-components'

const css = classNames.bind(styles)

const cancelIcon: IIconProps = { iconName: 'Cancel' }

/**
 * @example
 * ```javascript
 * dispatch(showModal({ component: Components.News, title: 'Hello' }))
 * ```
 * @returns Modal
 */
export const AppModal: React.FC = () => {
  const dispatch = useDispatch()
  const modal = useSelector((s) => s.ui.modal)

  useBodyClass('nonScrollable', modal.isOpen)

  useEffect(() => {
    Tracking.openModal(modal.component)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!modal) return null

  const CustomComponent = MapComponents(modal.component)

  if (!CustomComponent) return null

  return (
    <Modal
      titleAriaId={modal.title}
      isOpen={modal.isOpen}
      onDismiss={() => dispatch(hideModal())}
      isBlocking={false}
      overlay={{ className: 'glassMorph' }}
      containerClassName={`${css('modalStyles')} ${css(modal.component.toLowerCase())}`}
    >
      <div className="header">
        {modal.title
          && (
            <Stack horizontal padding="0 0 0 10px" className={css('headerTitle')} horizontalAlign="space-between" verticalAlign="center">
              <StackItem>
                <Text variant="mediumPlus">{modal.title}</Text>
              </StackItem>
            </Stack>
          )}
        <IconButton
          className={css(styles.closeButton, 'closeButton')}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={() => dispatch(hideModal())}
        />
      </div>
      <div className={css(styles.modalBody, 'modalBody')}>
        <CustomComponent {...modal.params} />
      </div>
    </Modal>
  )
}

export default AppModal
