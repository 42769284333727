import '../../../styles/keep-informed.module.scss'

import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Stack, StackItem } from '@fluentui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { buildYup } from "schema-to-yup"
import { AnyObjectSchema } from 'yup'

import Tracking from '../../../lib/gtag'
import { generateJsonSchema } from '../../../lib/utils'
import { GlobalContext } from '../../../pages/_app'
import InputMapping from '../input-mapping'
import Markdown from '../markdown'

export type KeepInformedProps = {
  title: string
}

export const KeepInformed = ({
  title = 'Keep me informed'
}: KeepInformedProps) => {
  const data = useContext(GlobalContext)

  const { apiUrl, button, content, fields, thankYou, title: realTitle } = data.subscription_form

  const [allLoaded, setLoaded] = useState(false)
  const [isCalculated, setCalculated] = useState(false)
  const [thankYouMessage, setThankYouMessage] = useState('')

  const { schema, config } = generateJsonSchema({ title, fields })

  const yupSchema = buildYup(schema, config)

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(yupSchema as unknown as AnyObjectSchema)
  })

  const handleErrors = (response) => {
    if (response.ok) {
      return response
    } else

      throw new Error('Something went wrong.')
  }

  const onSubmit = (formData) => {
    setThankYouMessage('Processing, please wait ...')
    setCalculated(true)

    let message = 'Processing, please wait ...'

    fetch(`${process.env.NEXT_PUBLIC_STRAPI_API_URL}${apiUrl}`, {
      method: 'POST',
      body: JSON.stringify({ data: formData }),
      headers: { 'Content-Type': 'application/json' }
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then(() => { message = thankYou; return message })
      .catch(() => { message = 'User with this email might exist!'; return message })
      .finally(() => {
        Tracking.eventObj({
          action: 'Subscriptions/Subscribed'
        })

        setTimeout(() => {
          setThankYouMessage(message)
        }, 1000)
      })
  }

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <>
      {allLoaded && (
        <>
          {!isCalculated ? (
            <Stack grow wrap>
              <StackItem className="informedSection padding-section" grow shrink style={{ backgroundColor: 'var(--color-white)' }}>
                <div className="">
                  <form className="row loginRow" onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-12">
                      <h2 className="h4">{realTitle || title}</h2>
                      {content && <Markdown content={content} />}
                    </div>
                    {fields.map((item, key) => (
                      <div key={key} className="col">
                        <InputMapping item={item} errors={errors} register={register} />
                      </div>
                    ))}
                    <div className="col-12">
                      <button className="btn btnThemePrimary btn-100" type="submit">
                        {button}
                      </button>
                    </div>
                  </form>
                </div>
              </StackItem>
            </Stack>
          ) : (
            <Stack grow wrap>
              <StackItem className="calcSection padding-section" grow shrink style={{ backgroundColor: 'var(--color-white)' }}>
                <div className="">
                  <div className="row calcRow">
                    <div className="col">
                      <h2 className="h3">{thankYouMessage}</h2>
                    </div>
                  </div>
                </div>
              </StackItem>
            </Stack>
          )}
        </>
      )}
    </>
  )
}

export default KeepInformed
