/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react'
import { SocialIcon } from 'react-social-icons'

import { Stack, StackItem } from '@fluentui/react'
import Link from 'next/link'

import Tracking from '../lib/gtag'
import { GlobalContext } from '../pages/_app'
import { GlobalContextType } from '../types/global'

import { Mapper } from './cmsSections'
import ContactUs from './contact-us/contact-us'
import Image from "./shared/image"

export const Footer = () => {
  const { header, footer_nav, footer_items, footer_cols } = useContext<GlobalContextType>(GlobalContext)

  const arrays = []
  const exploded = footer_items.split(',')
  let count = 0
  for (let i = 0; i < footer_cols; i++) {
    const elCount = +exploded[i] || footer_items.length
    arrays.push(footer_nav.slice(count, count += elCount))
  }

  const trackHandler = (e: any) => {
    const target = e.target.closest('a')?.getAttribute('aria-label') || e.target.closest('a')?.href
    Tracking.eventObj({
      action: `Social/Footer/${target.toUpperCase()}`
    })
  }

  const socialIconsConfig = { marginLeft: '20px', width: '30px', height: '30px' }

  return (
    <>
      <ContactUs />
      <footer className="footer-container">
        <div className="container-lg">
          <Stack horizontal wrap className="row" verticalAlign="center">
            <StackItem className="col responsive-footer-col footer-logo" title='59addeb'>
              <Link href="/" passHref>
                <a>
                  <Image
                    image={header.logo}
                    width={180}
                    height={60}
                    className='logoSize'
                    objectFit='contain'
                    layout='intrinsic'
                  />
                </a>
              </Link>
            </StackItem>
            {arrays.map((arr, i) => (
              <StackItem key={i} className="col responsive-footer-col">
                <ul className="quick-links">
                  {arr?.map((item, j) => (
                    <li key={j}>
                      <Mapper componentName={item.__component} myProps={{ item, styles: [], classes: item.classes, type: item.type }} />
                    </li>
                  ))}
                  {/* <li><button className="btn btnThemePrimary" type="button" onClick={() => openCalculator()} style={{ maxWidth: 'unset' }}>FREE Procurement Calculator</button></li> */}
                </ul>
              </StackItem>
            ))}
          </Stack>
          <Stack className="copyright">
            <StackItem>
              <Link href="/">Copyright 2021 Agromin Corporation • Oxnard, CA</Link>
              <SocialIcon onClick={(e) => trackHandler(e)} target="_blank" rel="nofollow" url="https://www.instagram.com/agrominpremiumsoils/?hl=bg" style={socialIconsConfig} />
              <SocialIcon onClick={(e) => trackHandler(e)} target="_blank" rel="nofollow" url="https://www.facebook.com/agromin" style={{ ...socialIconsConfig, marginLeft: '10px' }} />
              <SocialIcon onClick={(e) => trackHandler(e)} target="_blank" rel="nofollow" url="https://twitter.com/agromin" style={{ ...socialIconsConfig, marginLeft: '10px' }} />
              <SocialIcon onClick={(e) => trackHandler(e)} target="_blank" rel="nofollow" url="https://www.linkedin.com/company/agromin" style={{ ...socialIconsConfig, marginLeft: '10px' }} />
              <SocialIcon onClick={(e) => trackHandler(e)} target="_blank" rel="nofollow" url="https://www.youtube.com/@agrominsoilproducts5565" style={{ ...socialIconsConfig, marginLeft: '10px' }} />
            </StackItem>
          </Stack>
        </div>
      </footer>
    </>
  )
}

export default Footer
