/* eslint-disable max-len */
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import {
  IconButton, IIconProps, Stack, StackItem
} from '@fluentui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { buildYup } from "schema-to-yup"
import { AnyObjectSchema } from 'yup'

import { RootState, useSelector } from '../../lib/hooks/store-hooks'
import { generateJsonSchema } from '../../lib/utils'
import { GlobalContext } from '../../pages/_app'
import { hideContacts } from '../../store/ui'
import styles from '../../styles/contact-us.module.scss'
import InputMapping from '../shared/input-mapping'
import Markdown from '../shared/markdown'

const cancelIcon: IIconProps = { iconName: 'Cancel' }

export type ContactUsProps = {
  title?: string
}

// const phoneRegExp = /^\+?[1-9][0-9]{7,14}$/

const ContactUs = () => {
  const dispatch = useDispatch()
  const isLoaded = useSelector((store: RootState) => store.ui.contactUs)
  const data = useContext(GlobalContext)

  const { title, description, fields, submit, thankYou } = data.contact_form
  const [isCalculated, setCalculated] = useState(false)
  const [thankYouMessage, setThankYouMessage] = useState('')

  const { schema, config } = generateJsonSchema({ title, fields })

  const yupSchema = buildYup(schema, config)

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(yupSchema as unknown as AnyObjectSchema)
  })

  const handleErrors = (response: any) => {
    if (response.ok) {
      return response
    }

    throw new Error('Something went wrong.')
  }

  const onSubmit = (formData: any) => {
    setThankYouMessage('Processing your Inquiry, please wait!')
    setCalculated(true)

    let message = 'Processing your Inquiry, please wait!'

    fetch(`${process.env.NEXT_PUBLIC_STRAPI_API_URL}/api/contact-forms`, {
      method: 'POST',
      body: JSON.stringify({ data: formData }),
      headers: { 'Content-Type': 'application/json' }
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then(() => { message = thankYou; return message })
      .catch(() => { message = 'Please, try again later!'; return message })
      .finally(() => {
        setTimeout(() => {
          setThankYouMessage(message)
        }, 3000)
      })
  }

  return (
    <>
      {isLoaded && (
        <>
          {!isCalculated ? (
            <Stack grow wrap>
              <StackItem className="contactUsSection padding-section" grow shrink>
                <IconButton
                  className={styles.closeButton}
                  iconProps={cancelIcon}
                  ariaLabel="Close popup modal"
                  onClick={() => dispatch(hideContacts())}
                />
                <div className="">
                  <form className="row contactRow" onSubmit={handleSubmit(onSubmit)} id="contactUs">
                    <div className="col">
                      <h2 className="h4">{title}</h2>
                      {description && <Markdown content={description} />}
                    </div>
                    <div className="col">
                      <div className="row">
                        {fields.map((item, key) => (
                          <div key={key} className="col-12">
                            <InputMapping item={item} errors={errors} register={register} />
                          </div>
                        ))}
                        <div className="col-12">
                          <button className="btn btnThemePrimary btn-100" type="submit">
                            {submit}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </StackItem>
            </Stack>
          ) : (
            <Stack grow wrap>
              <StackItem className="calcSection padding-section" grow shrink style={{ backgroundColor: 'var(--color-themePrimary)' }}>
                <div className="container-lg">
                  <div className="row calcRow">
                    <div className="col">
                      <h3 className="h3 result" style={{ textAlign: 'center' }}>{thankYouMessage}</h3>
                    </div>
                  </div>
                </div>
              </StackItem>
            </Stack>
          )}
        </>
      )}
    </>
  )
}

export default ContactUs
