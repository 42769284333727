/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import '../../../styles/calc.module.scss'

import React, { useContext, useState } from 'react'
import Select from 'react-select'

import { Stack, StackItem } from '@fluentui/react'

import bioEnergyImg from '../../../assets/bioenergy-calc.jpg'
import compostImg from '../../../assets/compost-calc.jpg'
import mulchImg from '../../../assets/mulch-calc.jpg'
import energyImg from '../../../assets/renewable-calc.jpg'
import Tracking from '../../../lib/gtag'
import { GlobalContext } from '../../../pages/_app'
import { GlobalContextType } from '../../../types/global'

export type CalcProps = {
  title: string
  description: string
  attachment?: string
  isModal?: boolean
}

export type DropDownType = { label: string, value: number }

const styles = {
  menu: (provided: any) => ({
    ...provided,
    borderRadius: 0,
    maxHeight: '6rem'
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontFamily: 'din-condensed',
    maxHeight: '6rem'
  }),
  control: (provided: any) => ({
    ...provided,
    backgroundColor: 'transparent',
    borderRadius: 0,
    border: 0,
    borderBottom: '2px solid #C4DA5E',
    outline: 'unset',
    boxShadow: 'none'
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none'
  }),
  clearIndicator: (base: any) => ({
    ...base,
    color: 'white'
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: 'white' // Custom colour
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#C4DA5E',
    marginLeft: '-7px'
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: 'rgba(255,255,255,0.3)'
  }),
  groupHeading: (provided: any) => ({
    ...provided,
    fontSize: '0.75rem'
  }),
  option: (provided: any) => ({
    ...provided,
    color: 'var(--color-themePrimary)',
    fontSize: '0.7rem',
    backgroundColor: 'transparent'
  })
}

const Calc = ({
  title, isModal
}: CalcProps) => {
  const { calculator_data, ab1985 } = useContext<GlobalContextType>(GlobalContext)
  const [isCalculated, setCalculated] = useState(false)
  const [county, setCounty] = useState<any>()

  if (!calculator_data) return null

  const calculate = () => {
    Tracking.eventObj({
      action: 'Calculator/Calculate',
      label: county,
      value: {
        jurisdiction: county,
        year: new Date().getFullYear()
      }
    })
    setCalculated(true)
  }

  const reCalculate = () => {
    Tracking.eventObj({
      action: 'Calculator/Re-Calculate'
    })
    setCalculated(false)
  }

  return (
    <>
      {!isCalculated ? (
        <Stack grow wrap>
          <StackItem className="calcSection padding-section" grow shrink style={{ backgroundColor: isModal ? 'var(--color-themePrimary)' : 'var(--color-themePrimary)' }}>
            <div className="container-lg">
              <form className="row calcRow">
                <div className="col">
                  <h2 className="h4">Procurement Summary</h2>
                  <p>
                    Choose from your City or unincorporated area in the dropdown menu.
                  </p>
                </div>
                <div className="col">
                  <label htmlFor="county">
                    Jurisdiction
                    <Select
                      onChange={(val) => { setCounty(val) }}
                      options={calculator_data}
                      styles={styles}
                      isClearable
                      isSearchable
                      name="county"
                    />
                  </label>
                </div>
                <div className="col">
                  <label htmlFor="year">
                    Year
                    <input type="text" readOnly placeholder="year" name="year" value={new Date().getFullYear()} />
                  </label>
                </div>
                <div className="col">
                  <button disabled={!county} className="btn btnThemePrimaryLight" type="button" onClick={() => calculate()}>
                    Calculate
                  </button>
                </div>
              </form>
            </div>
          </StackItem>
        </Stack>
      ) : (
        <Stack grow wrap>
          <StackItem className="calcSection padding-section" grow shrink style={{ backgroundColor: 'var(--color-themePrimary)', padding: '1rem' }}>
            <div className="container-lg">
              <div className="row calcRow">
                <div className="col">
                  <h2 className="h7" style={{ color: 'white' }}>
                    {`City of ${county.label} - ${title} - ${new Date().getFullYear()}`}
                  </h2>
                </div>
              </div>
              <div className="row calcRow mt-30">
                <div
                  className="col p-10"
                  style={{
                    backgroundImage: `linear-gradient(to left, rgba(245, 246, 252, 0), rgba(0, 0, 0, 0.7)), url(${compostImg.src})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'
                  }}
                >
                  <p className="h6 result">
                    {`Compost - ${(Math.ceil(county.compost * (ab1985 / 100)))} tons`}
                  </p>
                  <p>
                    <small>
                      Application at least 10 tons per
                      acre at a half inch deep.
                    </small>
                  </p>
                  <p className="h6 result">
                    Acres Treated:
                    {' '}
                    <span>{Math.ceil((county.compost / 10) * (ab1985 / 100))}</span>
                  </p>
                </div>
              </div>
              <div className="row calcRow mt-10">
                <div
                  className="col p-10"
                  style={{
                    backgroundImage: `linear-gradient(to left, rgba(245, 246, 252, 0), rgba(0, 0, 0, 0.7)), url(${mulchImg.src})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'
                  }}
                >
                  <p className="h6 result">
                    {`Mulch - ${(Math.ceil(county.mulch * (ab1985 / 100)))} tons`}
                  </p>
                  <p>
                    <small>
                      Application at 100 tons per
                      acre at two inches deep.
                    </small>
                  </p>
                  <p className="h6 result">
                    Acres Treated:
                    {' '}
                    <span>{Math.ceil((county.mulch / 100) * (ab1985 / 100))}</span>
                  </p>
                </div>
              </div>
              <div className="row calcRow mt-10">
                <div
                  className="col p-10"
                  style={{
                    backgroundImage: `linear-gradient(to left, rgba(245, 246, 252, 0), rgba(0, 0, 0, 0.7)), url(${bioEnergyImg.src})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'
                  }}
                >
                  <p className="h6 result">
                    {`Bio Energy - ${(Math.ceil(county.bioenergy * (ab1985 / 100)))} kWh`}
                  </p>
                  <p>
                    <small>
                      680 kWh per
                      household per year.
                    </small>
                  </p>
                  <p className="h6 result">
                    Trucks Powered:
                    {' '}
                    <span>{((county.bioenergy / 680) * (ab1985 / 100)).toFixed(0)}</span>
                  </p>
                </div>
              </div>
              <div className="row calcRow mt-10">
                <div
                  className="col p-10"
                  style={{
                    backgroundImage: `linear-gradient(to left, rgba(245, 246, 252, 0), rgba(0, 0, 0, 0.7)), url(${energyImg.src})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'
                  }}
                >
                  <p className="h6 result">
                    {`RNG - ${(Math.ceil(county.rng * (ab1985 / 100)))} DGE`}
                  </p>
                  <p>
                    <small>
                      13,000 DGE per
                      truck per year.
                    </small>
                  </p>
                  <p className="h6 result">
                    Trucks Powered:
                    {' '}
                    <span>{((county.rng / 13000) * (ab1985 / 100)).toFixed(0)}</span>
                  </p>
                </div>
              </div>
              <div className="row calcRow">
                <div className="col" style={{ textAlign: 'center' }}>
                  {/* <button onClick={() => downloadPdf()} className="btn btnThemePrimaryLight" type="button">
                    Download pdf
                    {' '}
                    {attachment}
                  </button> */}
                  <button className="btn btnLink" type="button" onClick={() => { reCalculate(); setCounty(null) }}>Re-calculate</button>
                </div>
              </div>
            </div>
          </StackItem>
        </Stack>
      )}
    </>
  )
}

export default Calc
