import * as React from 'react'

import {
  FocusTrapCallout, FocusZone, Stack, Text
} from '@fluentui/react'
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button'
import { useBoolean, useId } from '@fluentui/react-hooks'
import classNames from 'classnames'

import styles from '../../../styles/callout.module.scss'

const css = classNames.bind(styles)

export const AppCallout: React.FC = () => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false)
  const buttonId = useId('callout-button')

  return (
    <>
      {isCalloutVisible ? (
        <FocusTrapCallout
          role="alertdialog"
          className={css('callout')}
          gapSpace={0}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          setInitialFocus
        >
          <Text block variant="xLarge" className={css('title')}>
            Focus trapping callout
          </Text>
          <Text block variant="small">
            Content is wrapped in a FocusTrapZone so the user cannot accidentally tab or focus out
            of this callout. Use the buttons to close.
          </Text>
          {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
          <FocusZone>
            <Stack className={css('buttons')} gap={8} horizontal>
              <PrimaryButton onClick={toggleIsCalloutVisible}>Done</PrimaryButton>
              <DefaultButton onClick={toggleIsCalloutVisible}>Cancel</DefaultButton>
            </Stack>
          </FocusZone>
        </FocusTrapCallout>
      ) : null}
    </>
  )
}

export default AppCallout
