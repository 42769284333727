/* eslint-disable max-len */
import React, { useContext } from 'react'

import { GlobalContext } from '../../pages/_app'

import ContactUsForm from './contact-us-classic'
import MailchimpEmbed from './contact-us-mailchimp-embed'

const ContactUs = () => {
  const data = useContext(GlobalContext)

  const { useMailchimpContactUs } = data

  return (
    <>
      {useMailchimpContactUs ? (
        <MailchimpEmbed />
      ) : (
        <ContactUsForm />
      )}
    </>
  )
}

export default ContactUs
