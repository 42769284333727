/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import React from 'react'

export const Here1 = () => (
  <>
    <div id="co_document" className="co_document co_codesStateAdminCodes">
      <div className="co_documentHead">
        <div className="co_cites">14 CCR § 18993.1</div>
        <div className="co_title">
          <div id="co_anchor_I8947EACDE4F04CF4847E7581594168C4" className="co_headtext">
            <strong>§ 18993.1. Recovered Organic Waste Product Procurement Target.</strong>
          </div>
        </div>
      </div>
      <div className="co_contentBlock co_section" id="co_anchor_ID0F448956FD64BD2AF58B246CD0342E5">
        <div className="co_contentBlock co_body">
          <div className="co_contentBlock co_subsection">
            <div className="co_paragraph" id="co_anchor_I8A45D1AD0C5147EB81058506C2899FBD">
              <div className="co_paragraphText">
                (a) Except as otherwise provided, commencing January 1, 2022, a jurisdiction
                shall annually procure a quantity of recovered organic waste products that meets or exceeds its current
                annual recovered organic waste product procurement target as determined by this article. For the purposes of
                this section, “jurisdiction” means a city, a county or a city and county.

              </div>
            </div>
          </div>
          <div className="co_contentBlock co_subsection">
            <div className="co_paragraph" id="co_anchor_I8D9DE4AA562B499C8DBD442B6950EBB2">
              <div className="co_paragraphText">
                (b) On or before January 1, 2022, and on or before January 1 every five
                years thereafter, the Department shall recalculate the annual recovered organic waste product procurement
                target for each jurisdiction according to the requirements of Subdivision (c).

              </div>
            </div>
          </div>
          <div className="co_contentBlock co_subsection">
            <div className="co_paragraph" id="co_anchor_I8329A09E6C6B417D8A3559230EBB0811">
              <div className="co_paragraphText">
                (c) Each jurisdiction&apos;s recovered organic waste product procurement target
                shall be calculated by multiplying the per capita procurement target by the jurisdiction population where:
              </div>
            </div>
            <div className="co_contentBlock co_subsection">
              <div className="co_paragraph" id="co_anchor_IC448DEB1AE8640C59A22DD3C4A0D771D">
                <div className="co_paragraphText co_indentLeft2">
                  (1) Per capita procurement target = 0.08 tons of organic
                  waste per California resident per year.

                </div>
              </div>
            </div>
            <div className="co_contentBlock co_subsection">
              <div className="co_paragraph" id="co_anchor_IDCE86AE9CB014C87BCCDBEF400692B7E">
                <div className="co_paragraphText co_indentLeft2">
                  (2) Jurisdiction population equals the number of residents
                  in a jurisdiction, using the most recent annual data reported by the California Department of Finance.
                </div>
              </div>
            </div>
          </div>
          <div className="co_contentBlock co_subsection">
            <div className="co_paragraph" id="co_anchor_I13CF42D12489487EA93E77B1823A0ACE">
              <div className="co_paragraphText">
                (d) Annually, the Department shall provide notice to each jurisdiction of
                its annual recovered organic waste product procurement target by posting such information on the
                Department&apos;s website and providing written notice directly to the jurisdiction.

              </div>
            </div>
          </div>
          <div className="co_contentBlock co_subsection">
            <div className="co_paragraph" id="co_anchor_IFFD77DC708D849EC8AD50B6D387D2F23">
              <div className="co_paragraphText">
                (e) A jurisdiction shall comply with Subdivision (a) by one or both of the
                following:

              </div>
            </div>
            <div className="co_contentBlock co_subsection">
              <div className="co_paragraph" id="co_anchor_I1930A9E63B28466F9878C9A7D3799CC7">
                <div className="co_paragraphText co_indentLeft2">
                  (1) Directly procuring recovered organic waste products for
                  use or giveaway.

                </div>
              </div>
            </div>
            <div className="co_contentBlock co_subsection">
              <div className="co_paragraph" id="co_anchor_IB8F32970F8E3434C8D375BBCC6D4BB53">
                <div className="co_paragraphText co_indentLeft2">
                  (2) Requiring, through a written contract or agreement,
                  that a direct service provider to the jurisdiction procure recovered organic waste products and provide
                  written documentation of such procurement to the jurisdiction.

                </div>
              </div>
            </div>
          </div>
          <div className="co_contentBlock co_subsection">
            <div className="co_paragraph" id="co_anchor_I8E6BD6D9B9BC4F46A230997881F5CA21">
              <div className="co_paragraphText">
                (f) For the purposes of this article, the recovered organic waste products
                that a jurisdiction may procure to comply with this article are:

              </div>
            </div>
            <div className="co_contentBlock co_subsection">
              <div className="co_paragraph" id="co_anchor_IF89DC5382EB847278201315D5B929E4D">
                <div className="co_paragraphText co_indentLeft2">
                  (1) Compost, subject to any applicable limitations of
                  Public Contract Code Section 22150, that is produced at:

                </div>
              </div>
              <div className="co_contentBlock co_subsection">
                <div className="co_paragraph" id="co_anchor_I3965286EE9E840A78374FB7EFB4CCCEA">
                  <div className="co_paragraphText co_indentLeft2">
                    (A) A compostable material handling operation or facility
                    permitted or authorized under Chapter 3.1 of this division; or

                  </div>
                </div>
              </div>
              <div className="co_contentBlock co_subsection">
                <div className="co_paragraph" id="co_anchor_IB42688F10D994F51B1FE143B18E71303">
                  <div className="co_paragraphText co_indentLeft2">
                    (B) A large volume in-vessel digestion facility as
                    defined and permitted under Chapter 3.2 of this division that composts on-site. [NOTE: Digestate, as
                    defined in Section 18982(a)(16.5), is a distinct material from compost and is thus not a recovered
                    organic waste product eligible for use in complying with this Article.]

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="co_contentBlock co_subsection">
            <div className="co_paragraph" id="co_anchor_I1D7BB20EF102450D95918648943EF23F">
              <div className="co_paragraphText co_indentLeft2">
                (2) Renewable gas used for fuel for transportation,
                electricity, or heating applications.

              </div>
            </div>
          </div>
          <div className="co_contentBlock co_subsection">
            <div className="co_paragraph" id="co_anchor_I8EAAB8F633914768AD299176950F8A48">
              <div className="co_paragraphText co_indentLeft2">(3) Electricity from biomass conversion.</div>
            </div>
          </div>
          <div className="co_contentBlock co_subsection">
            <div className="co_paragraph" id="co_anchor_I3677EB132F6541B292535A4889792808">
              <div className="co_paragraphText co_indentLeft2">
                (4) Mulch, provided that the following conditions are met for
                the duration of the applicable procurement compliance year:

              </div>
            </div>
            <div className="co_contentBlock co_subsection">
              <div className="co_paragraph" id="co_anchor_I671715FD91A641CCA1050CDDF670158C">
                <div className="co_paragraphText co_indentLeft2">
                  (A) The jurisdiction has an enforceable ordinance, or
                  similarly enforceable mechanism, that requires the mulch procured by the jurisdiction to comply with this
                  article to meet or exceed the physical contamination, maximum metal concentration, and pathogen density
                  standards for land application specified in Section 17852(a)(24.5)(A)1. through 3. of this division; and
                </div>
              </div>
            </div>
            <div className="co_contentBlock co_subsection">
              <div className="co_paragraph" id="co_anchor_I14C8A2DCADFB40938BBFF8DD7688BCA5">
                <div className="co_paragraphText co_indentLeft2">
                  (B) The mulch is produced at one or more of the following:
                </div>
              </div>
              <div className="co_contentBlock co_subsection">
                <div className="co_paragraph" id="co_anchor_I34BA52812F014FD0AD9F3ED44DE64C04">
                  <div className="co_paragraphText co_indentLeft6">
                    1. A compostable material handling operation or facility
                    as defined in Section 17852(a)(12), other than a chipping and grinding operation or facility as defined
                    in Section 17852(a)(10), that is permitted or authorized under this division; or

                  </div>
                </div>
              </div>
              <div className="co_contentBlock co_subsection">
                <div className="co_paragraph" id="co_anchor_I3C78FB4F226742E099BA30B44D69812F">
                  <div className="co_paragraphText co_indentLeft6">
                    2. A transfer/processing facility or transfer/processing
                    operation as defined in Sections 17402(a)(30) and (31), respectively, that is permitted or authorized
                    under this division; or

                  </div>
                </div>
              </div>
              <div className="co_contentBlock co_subsection">
                <div className="co_paragraph" id="co_anchor_I52E6315FE5544640912F9C794D72426F">
                  <div className="co_paragraphText co_indentLeft6">
                    3. A solid waste landfill as defined in Public Resources
                    Code Section 40195.1 that is permitted under Division 2 of Title 27 of the California Code of
                    Regulations.

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="co_contentBlock co_subsection">
            <div className="co_paragraph" id="co_anchor_IC73505A78FAF40078FD6A834C53A902B">
              <div className="co_paragraphText">
                (g) The following conversion factors shall be used to convert tonnage in the
                annual recovered organic waste product procurement target for each jurisdiction to equivalent amounts of
                recovered organic waste products:

              </div>
            </div>
            <div className="co_contentBlock co_subsection">
              <div className="co_paragraph" id="co_anchor_I826E4415DBE5469AA1180BDF3F856D22">
                <div className="co_paragraphText co_indentLeft2">
                  (1) One ton of organic waste in a recovered organic waste
                  product procurement target shall constitute:

                </div>
              </div>
              <div className="co_contentBlock co_subsection">
                <div className="co_paragraph" id="co_anchor_I704F4A3BB68846A4BA4EB6010FC04CE0">
                  <div className="co_paragraphText co_indentLeft2">
                    (A) 21 diesel gallon equivalents, or “DGE,” of renewable
                    gas in the form of transportation fuel.

                  </div>
                </div>
              </div>
              <div className="co_contentBlock co_subsection">
                <div className="co_paragraph" id="co_anchor_I0C93B342EE3040E9A2EA91D8E94C2A26">
                  <div className="co_paragraphText co_indentLeft2">
                    (B) 242 kilowatt-hours of electricity derived from
                    renewable gas.

                  </div>
                </div>
              </div>
              <div className="co_contentBlock co_subsection">
                <div className="co_paragraph" id="co_anchor_IFB55D1424D8D414D8D061FC1D7D06A31">
                  <div className="co_paragraphText co_indentLeft2">
                    (C) 22 therms for heating derived from renewable gas.
                  </div>
                </div>
              </div>
              <div className="co_contentBlock co_subsection">
                <div className="co_paragraph" id="co_anchor_I16B4191362F749D3B579B3C984CB9048">
                  <div className="co_paragraphText co_indentLeft2">
                    (D) 650 kilowatt-hours of electricity derived from
                    biomass conversion.

                  </div>
                </div>
              </div>
              <div className="co_contentBlock co_subsection">
                <div className="co_paragraph" id="co_anchor_I0A610336D2474A00AE3ABBF1C6BA6BFA">
                  <div className="co_paragraphText co_indentLeft2">
                    (E) 0.58 tons of compost or 1.45 cubic yards of compost.
                  </div>
                </div>
              </div>
              <div className="co_contentBlock co_subsection">
                <div className="co_paragraph" id="co_anchor_IA5A9BA2A75E34CCABA00C3BBFDEAD1D8">
                  <div className="co_paragraphText co_indentLeft2">(F) One ton of mulch.</div>
                </div>
              </div>
            </div>
          </div>
          <div className="co_contentBlock co_subsection">
            <div className="co_paragraph" id="co_anchor_I68C78A8116FC48308280D376FF99DE5B">
              <div className="co_paragraphText">
                (h) Renewable gas procured from a POTW may only count toward a
                jurisdiction&apos;s recovered organic waste product procurement target provided the following conditions are met
                for the applicable procurement compliance year:

              </div>
            </div>
            <div className="co_contentBlock co_subsection">
              <div className="co_paragraph" id="co_anchor_IF7B9445A31D2474F8CD539215D415690">
                <div className="co_paragraphText co_indentLeft2">
                  (1) The POTW receives organic waste directly from one or
                  more of the following:

                </div>
              </div>
              <div className="co_contentBlock co_subsection">
                <div className="co_paragraph" id="co_anchor_I886204C536A14433B07CD131D3C0A454">
                  <div className="co_paragraphText co_indentLeft2">
                    (A) A compostable material handling operation or facility
                    as defined in Section 17852(a)(12), other than a chipping and grinding operation or facility as defined
                    in Section 17852(a)(10), that is permitted or authorized under this division; or

                  </div>
                </div>
              </div>
              <div className="co_contentBlock co_subsection">
                <div className="co_paragraph" id="co_anchor_I349902514845491C8D6768BD4A63A86F">
                  <div className="co_paragraphText co_indentLeft2">
                    (B) A transfer/processing facility or transfer/processing
                    operation as defined in Sections 17402(a)(30) and (31), respectively, that is permitted or authorized
                    under this division; or

                  </div>
                </div>
              </div>
              <div className="co_contentBlock co_subsection">
                <div className="co_paragraph" id="co_anchor_I8118D913BB0F479D94BE7934FB72DAF4">
                  <div className="co_paragraphText co_indentLeft2">
                    (C) A solid waste landfill as defined in Public Resources
                    Code Section 40195.1 that is permitted under Division 2 of Title 27 of the California Code of
                    Regulations.

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="co_contentBlock co_subsection">
            <div className="co_paragraph" id="co_anchor_IFE9685F89FBF48DFAF895DB3C6DEEE38">
              <div className="co_paragraphText co_indentLeft2">
                (2) The POTW is in compliance with the exclusion described in
                Section 17896.6(a)(1).

              </div>
            </div>
          </div>
          <div className="co_contentBlock co_subsection">
            <div className="co_paragraph" id="co_anchor_I2360EC203A1A4BA2A1398FD0FC1634B9">
              <div className="co_paragraphText co_indentLeft2">
                (3) The jurisdiction receives a record from the POTW
                documenting
                the tons of organic waste received by the POTW from all solid waste facilities described in subsection
                (h)(1) above.

              </div>
            </div>
          </div>
          <div className="co_contentBlock co_subsection">
            <div className="co_paragraph" id="co_anchor_IFD55E5F315DF4293B0071575E3CC706D">
              <div className="co_paragraphText co_indentLeft2">
                (4) The amount of renewable gas a jurisdiction or
                jurisdictions
                procured from the POTW for fuel, electricity or heating applications is less than or equal to the POTW&apos;s
                production capacity of renewable gas generated from organic waste received at the POTW directly from solid
                waste facilities as determined using the relevant conversion factors in Subdivision (g).

              </div>
            </div>
          </div>
          <div className="co_contentBlock co_subsection">
            <div className="co_paragraph" id="co_anchor_I06777718EF224EB683169013DA817CEB">
              <div className="co_paragraphText co_indentLeft2">
                (5) The POTW transported less than 25 percent of the
                biosolids it
                produced to activities that constitute landfill disposal.

              </div>
            </div>
          </div>
          <div className="co_contentBlock co_subsection">
            <div className="co_paragraph" id="co_anchor_I40252F6F68EF4CE197E71E2325AA6EF8">
              <div className="co_paragraphText">
                (i) Electricity procured from a biomass conversion facility may only count
                toward a jurisdiction&apos;s recovered organic waste product procurement target if the biomass conversion
                facility receives feedstock directly from one or more of the following during the duration of the applicable
                procurement compliance year:

              </div>
            </div>
            <div className="co_contentBlock co_subsection">
              <div className="co_paragraph" id="co_anchor_I66FD698728B14895BFA01640DC7D857D">
                <div className="co_paragraphText co_indentLeft2">
                  (1) A compostable material handling operation or facility
                  as
                  defined in Section 17852(a)(12), other than a chipping and grinding operation or facility as defined in
                  Section 17852(a)(10), that is permitted or authorized under this division; or

                </div>
              </div>
            </div>
            <div className="co_contentBlock co_subsection">
              <div className="co_paragraph" id="co_anchor_I3149A6B38A3A447DB05F5E8DED2D9223">
                <div className="co_paragraphText co_indentLeft2">
                  (2) A transfer/processing facility or transfer/processing
                  operation as defined in Sections 17402(a)(30) and (31), respectively, that is permitted or authorized
                  under this division; or

                </div>
              </div>
            </div>
            <div className="co_contentBlock co_subsection">
              <div className="co_paragraph" id="co_anchor_IF0FF0030011D439D94916CBA60B3C54F">
                <div className="co_paragraphText co_indentLeft2">
                  (3) A solid waste landfill as defined in Public Resources
                  Code
                  Section 40195.1 that is permitted under Division 2 of Title 27 of the California Code of
                  Regulations.
                </div>
              </div>
            </div>
            <div className="co_contentBlock co_subsection">
              <div className="co_paragraph" id="co_anchor_IC891F3324618458A84E839CDA4A63A21">
                <div className="co_paragraphText">
                  (j) If a jurisdiction&apos;s annual recovered organic waste product procurement
                  target exceeds the jurisdiction&apos;s total procurement of transportation fuel, electricity, and gas for
                  heating
                  applications from the previous calendar year as determined by the conversion factors in Subdivision (g),
                  the
                  target shall be adjusted to an amount equal to its total procurement of those products as converted to
                  their
                  recovered organic waste product equivalent from the previous year consistent with Subdivision (g).

                </div>
              </div>
            </div>
            <div className="co_contentBlock co_subsection">
              <div className="co_paragraph" id="co_anchor_IBC278114D4F748FDB19CD0D897E05FC1">
                <div className="co_paragraphText">
                  (k) A jurisdiction shall identify additional procurement opportunities
                  within
                  the jurisdiction&apos;s departments and divisions for expanding the use of recovered organic waste products.
                </div>
              </div>
            </div>
            <div className="co_contentBlock co_subsection">
              <div className="co_paragraph" id="co_anchor_I77315117131548BEB263E5021745E253">
                <div className="co_paragraphText">
                  (
                  <em>l</em>
                  ) Rural counties, and jurisdictions located within rural
                  counties
                  that are exempt from the organic waste collection requirements pursuant to Section 18984.12(c), are not
                  required to comply with the procurement requirements in this Section from January 1, 2022-December 31,
                  2026.
                </div>
              </div>
            </div>
          </div>
          <div className="co_subsection">
            <div className="co_paragraph" id="co_anchor_IEE35AA52D5754C3985F04D4D28AAE9A4">
              <div className="co_paragraphText co_indentFirstLine1">
                Note: Authority cited: Sections 40502, 43020, 43021 and
                42652.5, Public Resources Code. Reference: Section 42652.5, Public Resources Code; and Sections 39730.6 and
                39730.8, Health and Safety Code.

              </div>
            </div>
          </div>
          <div id="co_anchor_I0F43697CFACF4FD2AF9B87324513F458" className="co_headtext co_hAlign2">HISTORY</div>
          <div className="co_paragraph" id="co_anchor_ICA552001F16D44B9A25303258F43B030">
            <div className="co_paragraphText">
              1. New article 12 (sections 18993.1-18993.4) and section filed 11-3-2020;
              operative
              1-1-2022 pursuant to Public Resources Code section 42652.2(a)(6) (Register 2020, No. 45).

            </div>
          </div>
          <div className="co_contentBlock co_includeCurrencyBlock" id="co_anchor_IEB1AF3F05A3147EE9B60F7967A2CE279">
            This
            database
            is current through 9/24/21 Register 2021, No. 39

          </div>
        </div>
        <div className="co_contentBlock co_cmdExpandedcite">14 CCR § 18993.1, 14 CA ADC § 18993.1</div>
      </div>
    </div>
  </>
)
