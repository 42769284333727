import React from 'react'

import dynamic from 'next/dynamic'

export const Mapper = ({ componentName, myProps }) => {
  const Component = dynamic(() => import(`./${componentName.replace('.', '/')}`))

  return (
    <Component {...myProps} />
  )
}

export const CmsSections = ({ data: components }) => {
  if (!components || !components?.length) return null

  return (
    <>
      {components.map((section, i) => (
        // eslint-disable-next-line no-underscore-dangle
        <Mapper key={i} componentName={section.__component} myProps={section} />
      ))}
    </>
  )
}

export default CmsSections