/* eslint-disable @next/next/no-img-element */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { memo, useContext, useEffect, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

import { IconButton } from '@fluentui/react/lib/Button'
import axios from 'axios'
import fileDownload from 'js-file-download'

import Tracking from '../../lib/gtag'
import { GlobalContext } from '../../pages/_app'
import { GlobalContextType } from '../../types/global'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const PdfPreview = () => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const { pdfPreview, setPdfPreview } = useContext<GlobalContextType>(GlobalContext)
  // const isMobile = useIsMobileSize()

  useEffect(() => { }, [pdfPreview])

  if (!pdfPreview) return null

  const forceDownload = (url, filename) => {
    axios.get(url, {
      responseType: 'blob',
    }).then(res => {

      Tracking.eventObj({
        category: 'Media/Download',
        action: `Download/${filename}`
      })
      fileDownload(res.data, filename)
    })
  }

  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1)
    }
  }

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1)
    }
  }

  const closeBtn = () => {
    setPageNumber(1)
    setNumPages(null)
    setPdfPreview(null)
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  return (
    <Document file={pdfPreview.url} onLoadSuccess={onDocumentLoadSuccess}>
      <Page pageNumber={pageNumber}>
        <div className="download-controls">
          <IconButton
            iconProps={{ iconName: 'Download' }}
            ariaLabel="Close popup modal"
            onClick={() => forceDownload(pdfPreview.url, pdfPreview.fileName)}
          />
        </div>
        <IconButton
          className={'closeButton'}
          iconProps={{ iconName: 'Cancel' }}
          ariaLabel="Close popup modal"
          onClick={() => closeBtn()}
        />
      </Page>
      <div className="page-controls">
        <button type="button" disabled={pageNumber == 1} onClick={() => prevPage()}>‹</button>
        <span>{pageNumber} of {numPages}</span>
        <button type="button" disabled={pageNumber == numPages} onClick={() => nextPage()}>›</button>
      </div>
    </Document>
  )
}

export default memo(PdfPreview)