/* eslint-disable import/prefer-default-export */
export const Hosts = {
  HeaderLogo: 'HeaderLogo',
  AfterHeader: 'AfterHeader',
  HeaderMenu: 'HeaderMenu',
  AfterMenu: 'AfterMenu',
  LogoInMenu: 'LogoInMenu',
  StickyMenu: 'StickyMenu'
}

export type ComponentsAsType = 'Error' | 'Calc' | 'TBD' | 'Login' | 'KeepInformed' | 'PrivacyPolicy' | 'detailed-requirements' | 'full-details'
