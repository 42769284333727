import React from 'react'
import ReactMarkdown from 'react-markdown'

import CustomLink from './custom-link'

export const Markdown = ({ content }: {content: any}) => (
  <ReactMarkdown components={{
    a: CustomLink as any
  }}>
    {content}
  </ReactMarkdown>
)

export default Markdown
