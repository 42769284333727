import React from 'react'

export const TBD = ({ title }: {title: string}) => (
  <p
    className="jumbotron"
    style={{
      color: 'var(--color-themePrimary)', width: '100%', height: '100%', display: 'flex', marginTop: '50px'
    }}
  >
    {title}
  </p>
)

export default TBD
