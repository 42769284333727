import NextImage from "next/image"

import { getStrapiMedia } from "../../lib/media"

type ImageProps = {
  image: any;
  className?: string;
  height?: string | number;
  width?: string | number;
  objectFit?: "contain" | "cover" | "fill" | "inherit" | "initial" | "none" | "revert" | "scale-down";
  layout?: "fill" | "fixed" | "intrinsic" | "responsive" | undefined;
  priority?: boolean;
}

const Image = ({ image, className, width, height, objectFit = 'contain', layout = "responsive", priority = false }: ImageProps) => {
  const { alternativeText, width: widthSize, height: heightSize } = image.hasOwnProperty('data') ? image.data : image

  let props = {}

  if (layout !== 'fill') {
    props = {
      width: width || widthSize,
      height: height || heightSize
    }
  }

  return (
    <NextImage
      layout={layout}
      objectFit={objectFit}
      src={getStrapiMedia(image)}
      className={className || ''}
      alt={alternativeText || ""}
      priority={priority}
      unoptimized
      {...props}
    />
  )
}

export default Image