/* eslint-disable no-unused-vars */
import React from 'react'
import { useDispatch } from 'react-redux'

import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton
} from '@fluentui/react'

import { useBodyClass } from '../../../lib/hooks/app-hooks'
import { useSelector } from '../../../lib/hooks/store-hooks'
import { hideDialog } from '../../../store/ui'
import { MapComponents } from '../../map-components'

/**
 * @example
 * ```javascript
 * dispatch(showModal({ component: Components.News, title: 'Hello' }))
 * ```
 * @returns Modal
 */
export const AppDialog = () => {
  const dispatch = useDispatch()
  const dialog = useSelector((s) => s.ui.dialog)

  useBodyClass('nonScrollable', !dialog?.isHidden)

  if (!dialog) return null

  const CustomComponent = MapComponents(dialog.component)

  const dialogContentProps = {
    type: DialogType.largeHeader,
    title: dialog.title,
    subText: dialog.subText
  }

  return (
    <Dialog
      hidden={dialog.isHidden}
      onDismiss={() => dispatch(hideDialog())}
      dialogContentProps={dialogContentProps}
      modalProps={{ isBlocking: true, overlay: { className: 'glassMorph' } }}
    >
      {CustomComponent ? <CustomComponent /> : null}
      <DialogFooter>
        <PrimaryButton onClick={() => dispatch(hideDialog())} text="Save" />
        <DefaultButton onClick={() => dispatch(hideDialog())} text="Cancel" />
      </DialogFooter>
    </Dialog>
  )
}

export default AppDialog
