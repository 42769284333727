import { ComponentsAsType } from '../configs/constants'

import {
  Calc, Error, Here1, Here2, KeepInformed,
  Login,
  TBD
} from './shared'

export const MapComponents: any = (comp: ComponentsAsType) => {
  switch (comp) {
  case 'Error':
    return Error
  case 'Calc':
    return Calc
  case 'TBD':
    return TBD
  case 'Login':
    return Login
  case 'KeepInformed':
    return KeepInformed
  case 'detailed-requirements':
    return Here1
  case 'full-details':
    return Here2
  default:
    return null
  }
}

export default MapComponents
