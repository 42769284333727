export const getTemplateProps = (template, styles, passedDesigns = {}) => {
  let returnVal = null

  if (template?.type === 'class') returnVal = {
    className: template?.styles.map((item) => (
      styles ? styles[item] : item
    )).join(" ")
  }
  else if (template?.type === 'combine') returnVal = {
    className: template?.styles?.class.map((item) => (
      styles ? styles[item] : item
    )).join(" "),
    style: template?.styles?.styles
  }
  else {
    returnVal = { 
      style: template?.styles
    }
  }

  returnVal.className = `${passedDesigns.myClasses} ${returnVal.className}`
  returnVal.style = {...passedDesigns.myStyles, ...returnVal.style}

  return returnVal 
}

/**
 * 
 * @param { title, fields } data 
 * @returns Json Schema { validation, errors}
 */
export const generateJsonSchema = ({ title, fields }) => {
  let properties = {}

  fields.forEach(element => {
    properties[element.name] = element.validations
  })

  const validation = {
    $schema: "http://json-schema.org/draft-07/schema#",
    title,
    type: "object",
    properties
  }

  let messages = {
    schemaType: "type-def", 
    errMessages: { 
      $required: "Required field"
    }
  }

  fields.forEach(element => {
    messages.errMessages[element.name] = element.validations?.errMessages ? element.validations?.errMessages : element.validations?.errMessage
  })

  return {
    schema: validation,
    config: messages
  }
}

// Function that save the data in session storage by key
export const saveData = (key, data) => {
  sessionStorage.setItem(key, JSON.stringify(data))
}

// Function that get the data from session storage by key
export const getData = (key) => JSON.parse(sessionStorage.getItem(key))

// Function that remove the data from session storage by key
export const removeData = (key) => {
  sessionStorage.removeItem(key)
}

// Function that clear the session storage
export const clearData = () => {
  sessionStorage.clear()
}