import React, { createContext, useEffect, useMemo } from "react"
import { Provider, useDispatch } from "react-redux"

import { initializeIcons, loadTheme, Stack, ThemeProvider } from '@fluentui/react'
import App from "next/app"
import Head from "next/head"
import { Router } from "next/router"

import Footer from "../components/footer"
import Navbar from '../components/navbar/navbar'
import AppCallout from "../components/shared/callout/callout"
import AppDialog from "../components/shared/dialog/dialog"
import AppModal from "../components/shared/modal/modal"
import PdfPreview from "../components/shared/pdf-preview"
import Spinner from "../components/shared/spinner/spinner"
import { myTheme } from '../configs/theme'
import { fetchAPI } from "../lib/api"
import Tracking from "../lib/gtag"
import { getStrapiMedia } from "../lib/media"
import withReduxStore from '../lib/with-redux-store'
import { startLoader, stopLoader } from "../store/ui"
import { GlobalContextType, _APP } from "../types/global"

import "../styles/globals.scss"

React.useLayoutEffect = React.useEffect

loadTheme(myTheme)
initializeIcons()

export function reportWebVitals({ id, name, label, value }) {
  Tracking.eventObj({
    action: name,
    category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    label: id, // id unique to current page load
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    non_interaction: true, // avoids affecting bounce rate.
  })
}

// Store Strapi Global object in context
export const GlobalContext = createContext<GlobalContextType>({})

const MyApp = ({ Component, pageProps, reduxStore }: _APP) => {
  const { global, ...props } = pageProps

  // PDF Preview in Global Context
  const [pdfPreview, setPdfPreview] = React.useState(null)
  const pdfToPreview = useMemo(() => ({ pdfPreview, setPdfPreview }), [pdfPreview])

  const GetComponent = ({ Component, props }) => {
    const dispatch = useDispatch()
    useEffect(() => {
      Router.events.on('routeChangeStart', () => dispatch(startLoader()))
      Router.events.on('routeChangeComplete', () => dispatch(stopLoader()))
    })

    return <Component {...props} />
  }

  return (
    <>
      <ThemeProvider applyTo="body" theme={myTheme}>
        <Provider store={reduxStore}>
          <Head>
            <link
              rel="shortcut icon"
              href={getStrapiMedia(global?.favicon)}
            />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta charSet="utf-8" />
            <>
              {/* If there are any global scripts, add them here */}
              {(global as GlobalContextType)?.scripts?.map((script, a) => (
                <script key={a} id={script.title} dangerouslySetInnerHTML={{ __html: script.content }} />
              ))}
            </>
            <>
              {/* If there are any global scripts, add them here */}
              {(global as GlobalContextType)?.styles?.map((style, a) => (
                <style key={a} id={style.title}>
                  {style.content}
                </style>
              ))}

            </>
          </Head>
          <GlobalContext.Provider value={{ ...global, ...pdfToPreview }}>
            {/* #redline */}
            <Navbar />
            <Stack styles={{ root: { justifyContent: 'flex-start', flexWrap: 'nowrap' } }} style={{ display: 'flex', flex: 1, flexDirection: 'column', padding: '0' }} className="site-content" grow>
              <GetComponent Component={Component} props={props} />
            </Stack>
            <Footer />
            <Spinner />
            <AppModal />
            <AppDialog />
            <AppCallout />
            <PdfPreview />
          </GlobalContext.Provider>
        </Provider>
      </ThemeProvider>
    </>
  )
}

// getInitialProps disables automatic static optimization for pages that don't
// have getStaticProps. So article, category and home pages still get SSG.
// Hopefully we can replace this with getStaticProps once this issue is fixed:
// https://github.com/vercel/next.js/discussions/10949
MyApp.getInitialProps = async (ctx) => {
  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(ctx)
  // Fetch global site settings from Strapi
  const { data } = await fetchAPI("/global")

  const { header_nav: headerNavProp, footer_nav: footerNavProp, ...otherProps } = data

  const ctas = await fetchAPI("/call-to-action")

  const header_nav = headerNavProp?.items?.map((item) => ({
    title: item.title,
    slug: item?.page?.slug,
    event: item.event,
    modal: item.modal,
    advanced_props: item.advanced_props,
    template: item.template,
    url: item?.url,
    __component: item.__component
  }))

  const footer_nav = footerNavProp?.items?.map((item) => ({
    title: item.title,
    slug: item?.page?.slug,
    event: item.event,
    modal: item.modal,
    advanced_props: item.advanced_props,
    url: item?.url,
    template: item.template,
    __component: item.__component
  }))

  const { data: contactsForm } = await fetchAPI("/contact-us")

  const { data: keepUsInformed } = await fetchAPI("/keep-me-informed")

  const { data: calculatorData } = await fetchAPI("/calculators", {
    pagination: {
      pageSize: 100
    }
  })

  // Pass the data to our page via props
  return {
    ...appProps,
    pageProps: {
      global: {
        ...otherProps,
        ctas: ctas?.data?.actions,
        header_nav,
        footer_nav,
        footer_items: footerNavProp.itemOrganization,
        footer_cols: footerNavProp.columns,
        contact_form: contactsForm,
        subscription_form: keepUsInformed,
        calculator_data: calculatorData
      }
    }
  }
}

export default withReduxStore(MyApp)