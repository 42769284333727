import { TypedUseSelectorHook, useSelector as useSelect } from 'react-redux'

import { Action, ThunkAction } from '@reduxjs/toolkit'

import { createStore } from '../../store/store'

const store = createStore()

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useSelector: TypedUseSelectorHook<RootState> = useSelect

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

export default store
