/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import {
  AnimationClassNames,
  Icon,
  Layer,
  LayerHost,
  Stack,
  StackItem
} from '@fluentui/react'
import classNames from 'classnames'
import Link from "next/link"

import { Hosts } from '../../configs/constants'
import { useIsMobileSize, useScroll } from "../../lib/hooks/app-hooks"
import { useSelector } from "../../lib/hooks/store-hooks"
import { GlobalContext } from "../../pages/_app"
import { closeMenu, openMenu } from "../../store/ui"
import styles from '../../styles/navbar.module.scss'
import { GlobalContextType } from "../../types/global"
import { Mapper } from "../cmsSections"
import Image from "../shared/image"
const css = classNames.bind(styles)

export const themedExtraSmallStackTokens = {
  childrenGap: 'l2',
  padding: 'm'
}

export const Menu = ({ isMobile = false }) => {
  const { header_nav: _items } = useContext<GlobalContextType>(GlobalContext)

  return (
    <Stack horizontal={!isMobile} horizontalAlign="space-between" verticalAlign="center" className={`${css(styles.menuStyles)} ${css(styles.stackStyles)} ${css(styles.stackStylesShadow)} ${AnimationClassNames.slideDownIn20}`} tokens={themedExtraSmallStackTokens}>
      {!isMobile && (
        <StackItem>
          <LayerHost id={Hosts.LogoInMenu} />
        </StackItem>
      )}
      <StackItem>
        <Stack horizontal={!isMobile} tokens={{ ...themedExtraSmallStackTokens, padding: 0 }} className={`${isMobile ? styles.isMobileMenu : null}`}>
          {_items.map((item, i) => (
            <Mapper key={i} componentName={item.__component} myProps={{ item, styles }} />
          ))}
        </Stack>
      </StackItem>
    </Stack>
  )
}

Menu.defaultProps = {
  isMobile: false,
  closeMenu: () => { }
}

export const Navbar = () => {
  const { header } = useContext<GlobalContextType>(GlobalContext)

  const { scrollY, scrollDirection } = useScroll()
  const isMobile = useIsMobileSize()
  const [logoPosition, setLogoPosition] = useState(Hosts.HeaderLogo)
  const [menuPosition, setMenuPosition] = useState<string>(Hosts.HeaderMenu)
  const [logoVersion, setLogoVersion] = useState(header.logo)
  const dispatch = useDispatch()
  const showMobileMenu = useSelector(state => state.ui.isMenuOpen)

  useEffect(() => {
    if (scrollY > 153 && scrollDirection === 'up') {
      setTimeout(() => {
        setLogoPosition(Hosts.LogoInMenu)
        setLogoVersion(header.logo_inverted)
      }, 300)
    } else if (scrollY < 100 && scrollDirection === 'down') {
      setTimeout(() => {
        setLogoPosition(Hosts.HeaderLogo)
        setLogoVersion(header.logo)
      }, 300)
    }
    if (!isMobile) {
      if (scrollY > 153 && scrollDirection === 'up') {
        setMenuPosition('')
      } else if (scrollY < 100 && scrollDirection === 'down') {
        setMenuPosition(Hosts.HeaderMenu)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollY])

  const showHideMenu = () => {
    showMobileMenu ? dispatch(closeMenu()) : dispatch(openMenu())
  }

  const LogoLayer = (
    <Layer hostId={logoPosition}>
      <Link
        href="/"
        passHref
      >
        <a onClick={() => dispatch(closeMenu())} className={`${css(styles.itemStyle)} ${css(styles[logoPosition])} ${AnimationClassNames.scaleUpIn100}`}>
          <Image
            image={logoVersion}
            width={170}
            height={50}
            className='logoSize'
            priority
          />
        </a>
      </Link>
    </Layer>
  )

  const MenuLayer = (
    <Layer hostId={menuPosition}>
      <Menu />
    </Layer>
  )

  return (
    <>
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center" className={css(styles.contentClass)}>
        <StackItem align="start">
          <LayerHost id={Hosts.HeaderLogo} />
        </StackItem>
        {!isMobile && (
          <StackItem
            align="center"
            className={styles.poweredBy}
          >
            <span>{header.powered_by}</span>

            <Link href="https://agromin.com" passHref>
              <a target="_blank" rel="noopener noreferrer">
                <Image
                  image={header.agromin_logo}
                  width={170}
                  height={50}
                  className='agrominLogo'
                  priority
                />
              </a>
            </Link>
          </StackItem>
        )}
      </Stack>
      <Stack>
        <StackItem style={{ minWidth: '22rem' }}>
          <LayerHost id={Hosts.HeaderMenu} style={{ height: !isMobile ? '54px' : '' }}>
            {isMobile && (
              <>
                <button type="button" className={styles.burgerMenu} onClick={() => showHideMenu()}>
                  <TransitionGroup component={null}>
                    <CSSTransition
                      in
                      key={showMobileMenu.toString()}
                      timeout={300}
                      classNames={{
                        enter: styles.OpenMenuEnter,
                        enterActive: styles.OpenMenuEnterActive,
                        exit: styles.OpenMenuExit,
                        exitActive: styles.OpenMenuExitActive
                      }}
                    >
                      <Icon className={styles.iconPosition} iconName={showMobileMenu ? 'Cancel' : 'GlobalNavButton'} />
                    </CSSTransition>
                  </TransitionGroup>

                </button>
                {showMobileMenu && <Menu isMobile closeMenu={showHideMenu} />}
              </>
            )}
          </LayerHost>
        </StackItem>
        <StackItem>
          <LayerHost id={Hosts.AfterMenu} />
        </StackItem>
      </Stack>
      {LogoLayer}
      {!isMobile && MenuLayer}
    </>
  )
}

export default Navbar