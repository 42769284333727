import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'

import { Spinner as Loader, SpinnerSize } from '@fluentui/react'
import classNames from 'classnames'

import { useBodyClass } from '../../../lib/hooks/app-hooks'
import type { RootState } from '../../../lib/hooks/store-hooks'
import { GlobalContext } from '../../../pages/_app'
import styles from '../../../styles/spinner.module.scss'
import Image from '../../shared/image'

const css = classNames.bind(styles)

export const Spinner = ({ isLoading }) => {
  const { favicon } = useContext(GlobalContext)
  const pageTitle = "Welcome"

  useBodyClass('nonScrollable', isLoading)
  return (
    <CSSTransition
      in={isLoading}
      timeout={500}
      classNames={{
        enter: styles.fadeIn200Enter,
        enterActive: styles.fadeIn200EnterActive,
        exit: styles.fadeIn200Exit,
        exitActive: styles.fadeIn200ExitActive
      }}
      mountOnEnter
      unmountOnExit
    >
      <div className={css(styles.spinnerOverlay, 'glassMorph')}>
        <Loader
          size={SpinnerSize.large}
          styles={{
            label: {
              fontSize: '26px', fontWeight: '500', textShadow: '1px 1px 2px rgba(0,0,0,0.6)', marginTop: '20px', color: 'white'
            },
            circle: {
              width: '100px', height: '100px', borderWidth: '2px', borderRightColor: 'white', borderLeftColor: 'white', borderBottomColor: 'white'
            }
          }}
          className={css(styles.fullSize)}
          label={pageTitle || 'Loading...'}
        />
        <Image image={favicon} className={css(styles.bearLoading)} objectFit='contain' width={300} height={90} layout={undefined} />
      </div>
    </CSSTransition>
  )
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.ui.isLoading
})

export default connect(mapStateToProps, {})(Spinner)
