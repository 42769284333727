import '../../../styles/login.module.scss'

import React, { useEffect, useState } from 'react'

import { Stack, StackItem } from '@fluentui/react'

import Tracking from '../../../lib/gtag'

export type LoginProps = {
  title: string
}

export const Login = ({
  title = 'Agromin Customer Portal'
}: LoginProps) => {
  const [allLoaded, setLoaded] = useState(false)
  const [isCalculated, setCalculated] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])

  const send = () => {
    Tracking.eventObj({
      action: 'Login/Submit'
    })
    setCalculated(true)
  }

  const close = () => {
    setLoaded(false)
    setCalculated(false)
  }

  return (
    <>
      {allLoaded && (
        <>
          {!isCalculated ? (
            <Stack grow wrap>
              <StackItem className="calcSection padding-section" grow shrink style={{ backgroundColor: 'var(--color-white)' }}>
                <div className="">
                  <form className="row loginRow">
                    <div className="col-12">
                      <h2 className="h4">{title}</h2>
                      <p>
                        Here you can access the Agromin Customer Portal
                      </p>
                    </div>
                    <div className="col">
                      <label htmlFor="city">
                        Username
                        <input type="text" value="" placeholder="username" name="username" />
                      </label>
                    </div>
                    <div className="col">
                      <label htmlFor="county">
                        Password
                        <input type="password" value="" placeholder="password" name="password" />
                      </label>
                    </div>
                    <div className="col-12">
                      <button className="btn btnThemePrimary btn-100" type="button" onClick={() => send()}>
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </StackItem>
            </Stack>
          ) : (
            <Stack grow wrap>
              <StackItem className="loginSection padding-section" grow shrink style={{ backgroundColor: 'var(--color-white)' }}>
                <div className="">
                  <div className="row calcRow">
                    <div className="col">
                      <h2 className="h3">Can&apos;t login right now!</h2>
                      <p>This platform is still not available, please try again later!</p>
                    </div>
                    <div className="col">
                      <button className="btn btnLink" type="button" onClick={() => close()}>Close</button>
                    </div>
                  </div>
                </div>
              </StackItem>
            </Stack>
          )}
        </>
      )}
    </>
  )
}

export default Login
