import Link from "next/link"

const isExternalLink = (url) => {

  if (typeof window !== "undefined") {
    const host = window.location.hostname

    const linkHost = function (url) {
      if (/^https?:\/\//.test(url)) { // Absolute URL.
        // The easy way to parse an URL, is to create <a> element.
        // @see: https://gist.github.com/jlong/2428561
        const parser = document.createElement('a')
        parser.href = url

        return parser.hostname
      }
      else { // Relative URL.
        return window.location.hostname
      }
    }(url)

    return host !== linkHost || (host === linkHost && url.indexOf('/uploads/') >= 0)
  }
  return false
}

export default function CustomLink({ as, href, ...otherProps }) {

  if (isExternalLink(href)) {
    otherProps['target'] = '_blank'
    otherProps['rel'] = 'nofollow'
  }

  return (
    <Link as={as} href={href} passHref>
      <a {...otherProps} />
    </Link>
  )
}