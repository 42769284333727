/* eslint-disable max-len */
import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'

import {
  IconButton, IIconProps, Stack, StackItem
} from '@fluentui/react'

import { RootState, useSelector } from '../../lib/hooks/store-hooks'
import { GlobalContext } from '../../pages/_app'
import { hideContacts } from '../../store/ui'
import styles from '../../styles/contact-us.module.scss'
import Markdown from '../shared/markdown'

const cancelIcon: IIconProps = { iconName: 'Cancel' }

export type ContactUsProps = {
  title?: string
}

const ContactUs = () => {
  const dispatch = useDispatch()
  const isLoaded = useSelector((store: RootState) => store.ui.contactUs)
  const data = useContext(GlobalContext)

  const { title, description } = data.contact_form

  const { mailChimpFormUrl, formHeightInPixels } = data

  return (
    <>
      {isLoaded && (
        <>
          <Stack grow wrap>
            <StackItem className="contactUsSection padding-section" grow shrink>
              <IconButton
                className={styles.closeButton}
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                onClick={() => dispatch(hideContacts())}
              />
              <div className="">
                <form className="row contactRow" id="contactUs">
                  <div className="col">
                    <h2 className="h4">{title}</h2>
                    {description && <Markdown content={description} />}
                  </div>
                  <div className="col">
                    <div className="row">
                      <embed type="text/html" src={mailChimpFormUrl} width="100%" height={`${formHeightInPixels}px`} />
                    </div>
                  </div>
                </form>
              </div>
            </StackItem>
          </Stack>
        </>
      )}
    </>
  )
}

export default ContactUs
