import { createSlice } from '@reduxjs/toolkit'

export interface ModalState {
  isOpen: boolean;
  component: string;
  title?: string;
  params?: object;
}

export interface DialogState {
  isHidden: boolean;
  component: string;
  title?: string;
  subText?: string;
}

export interface State {
  modal: ModalState,
  dialog: DialogState,
  contactUs: boolean,
  isLoading: boolean,
  isMenuOpen: boolean
}

/**
 * Creating slice of data for the redux store
 */
export const slice = createSlice({
  name: 'ui',
  initialState: {
    modal: {},
    dialog: {},
    contactUs: false,
    isLoading: false,
    isMenuOpen: false
  } as State,
  reducers: {
    showModal(state: State, { payload }) {
      state.modal = {
        isOpen: true, component: payload.component, title: payload.title, params: payload.params
      }
    },
    hideModal(state: State) {
      if (typeof window !== "undefined") {
        window.history.replaceState(null, null, window.location.href.split('#')[0])
      }
      state.modal = { ...state.modal, isOpen: false }
    },
    showDialog(state: State, { payload }) {
      // eslint-disable-next-line no-console
      state.dialog = {
        // eslint-disable-next-line max-len
        isHidden: false, component: payload.component, title: payload.title, subText: payload.subText
      }
    },
    hideDialog(state: State) {
      state.dialog = { ...state.modal, isHidden: true }
    },
    showContacts(state: State) {
      state.contactUs = true
    },
    hideContacts(state: State) {
      state.contactUs = false
    },
    startLoader(state: State) {
      state.isLoading = true
    },
    stopLoader(state: State) {
      state.isLoading = false
    },
    openMenu(state: State) {
      state.isMenuOpen = true
    },
    closeMenu(state: State) {
      state.isMenuOpen = false
    }
  }
})

export const { reducer } = slice
export const {
  showModal, hideModal, showDialog, hideDialog, showContacts, hideContacts, startLoader, stopLoader, openMenu, closeMenu
} = slice.actions

export const { actions } = slice
