import { useCallback, useEffect, useRef, useState } from 'react'

import moment from 'moment'

const calculateDuration = eventTime => 
  // return difference between current time and (event time plus 15 minutes) in seconds as a moment object
   moment.duration(moment().diff(moment(eventTime).add(15, 'minutes'))).abs()

const Countdown = ({ eventTime, interval }) => {
  const [duration, setDuration] = useState(calculateDuration(eventTime))
  // timerRef is a mutable ref object whose .current property is initialized to default Timer value (null).
  const timerRef = useRef(null)
  const timerCallback = useCallback(() => {
    setDuration(calculateDuration(eventTime))
  }, [eventTime])

  useEffect(() => {
    timerRef.current = setInterval(timerCallback, interval)

    return () => {
      clearInterval(timerRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventTime])

  return (
    <>
      <span>{duration.minutes()} Minutes {duration.seconds()} Seconds</span>
    </>
  )
}

export default Countdown