export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS

// export pageview
export const pageview = (url) => {
  window.gtag("config", GA_TRACKING_ID, {
    page_path: url,
  })
}

// export event
export const event = (action, category, label, value) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}

// export event
export const eventObj = ({action, category = 'Interactions', label = null, value = null, non_interaction = true}) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value,
    non_interaction
  })
}

// export event open modal
export const openModal = (label) => {
  window.gtag("event", 'open', {
    event_category: 'modal',
    event_label: label
  })
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  event,
  pageview,
  openModal,
  eventObj
}