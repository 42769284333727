import {
  createTheme, Depths
} from '@fluentui/react'

/**
 * Setup the main theme
 */
export const myTheme = createTheme({
  palette: {
    themePrimary: '#366d4f',
    themeLighterAlt: '#f4f9f6',
    themeLighter: '#d5e8de',
    themeLight: '#b4d3c2',
    themeTertiary: '#75a88c',
    themeSecondary: '#477f60',
    themeDarkAlt: '#316348',
    themeDark: '#2a533c',
    themeDarker: '#1f3d2d',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#c6c6c6',
    neutralSecondary: '#8c8c8c',
    neutralPrimaryAlt: '#575757',
    neutralPrimary: '#404040',
    neutralDark: '#303030',
    black: '#242424',
    white: '#ffffff'
  },
  effects: {
    elevation64: Depths.depth8
  },
  defaultFontStyle: {
    fontSize: '1rem',
    fontFamily: 'din-condensed, sans-serif'
  }
})

export const myDocumentTheme = createTheme({
  palette: {
    themePrimary: '#366d4f',
    themeLighterAlt: '#f4f9f6',
    themeLighter: '#d5e8de',
    themeLight: '#b4d3c2',
    themeTertiary: '#75a88c',
    themeSecondary: '#477f60',
    themeDarkAlt: '#316348',
    themeDark: '#2a533c',
    themeDarker: '#1f3d2d',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#c6c6c6',
    neutralSecondary: '#8c8c8c',
    neutralPrimaryAlt: '#575757',
    neutralPrimary: '#404040',
    neutralDark: '#303030',
    black: '#242424',
    white: '#ffffff'
  },
  effects: {
    elevation64: Depths.depth8
  },
  defaultFontStyle: {
    fontSize: '0.6rem',
    fontFamily: 'din-condensed, sans-serif',
    whiteSpace: 'pre-wrap!important'
  },
})



export default myTheme
